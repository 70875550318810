import { React, Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import Spinner from "./Components/Loading/Spinner";
import "./Assets/style/Spinner.css";
import { BrowserRouter } from "react-router-dom";
import 'primereact/resources/themes/saga-blue/theme.css';  // Choose your theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { ThemeProvider as CustomThemeProvider } from "./themes/ThemeContext/ThemeContext";
const LazyApp = lazy(() => import("./App"));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={<Spinner />}>
    <CustomThemeProvider>
      <BrowserRouter>
        <LazyApp />
      </BrowserRouter>
    </CustomThemeProvider>
  </Suspense>
);
