import React from 'react'
import logo from '../../Assets/images/Loading/icon.png'
function Spinner() {
    return (
        <div className='fallback-spinner vh-100'>
          <img className='fallback-logo' src={logo} alt='logo'/>
          <div className='loading'>
            <div className='effect-1 effects'></div>
            <div className='effect-2 effects'></div>
            <div className='effect-3 effects'></div>
          </div>
        </div>
      )
}

export default Spinner