// 19-7-24 FOR HANDLE THEME GLOBALLY
import { createTheme } from '@mui/material/styles';

const PRIMARY_BACKGROUND_COLOR = '#FFA500'; // Orange
const SECONDARY_BACKGROUND_COLOR = '#FF8C00'; // Dark Orange
const PRIMARY_TEXT_COLOR = '#FFFFFF'; // White
const SECONDARY_TEXT_COLOR = '#F5F5F5'; // Light Gray
const BUTTON_TEXT_COLOR = '#FFFFFF'; // White
const BUTTON_BACKGROUND_COLOR = '#FF7F50'; // Coral
const PLACEHOLDER_BACKGROUND_COLOR = '#FFA500'; // Orange
const PLACEHOLDER_BORDER_COLOR = '#FF8C00'; // Dark Orange
const PLACEHOLDER_TEXT_COLOR = '#FFDAB9'; // Peach Puff
const ALT_TEXT_COLOR = '#FFE4B5'; // Moccasin
const TEXT_COLOR = '#FFFFFF'; // White
const NOTIFICATION_COLOR = '#FFA500'

// Create a theme with global color variables and urgency colors
const whiteOrangeTheme = createTheme({
  palette: {
    background: {
      default: PRIMARY_BACKGROUND_COLOR,
      paper: '#002244', // Darker Navy Blue
    },
    text: {
      primary: PRIMARY_TEXT_COLOR, // Form label color
    },
  },
  typography: {
    fontFamily: 'Georgia',
    fontSize: 14,
  },
  custom: {
    header: {
      backgroundColor: PRIMARY_BACKGROUND_COLOR,
      color: PRIMARY_TEXT_COLOR,
    },
    sideBar: {
      backgroundColor: PRIMARY_BACKGROUND_COLOR,
      color: PRIMARY_TEXT_COLOR,
    },
    main: {
      backgroundColor: PRIMARY_BACKGROUND_COLOR,
    },
    submitButton: {
      backgroundColor: BUTTON_BACKGROUND_COLOR,
      textColor: BUTTON_TEXT_COLOR,
    },
    cancelButton: {
      backgroundColor: SECONDARY_BACKGROUND_COLOR,
      textColor: BUTTON_TEXT_COLOR,
      borderColor: PRIMARY_TEXT_COLOR,
    },
    placeholder: { // Normal text placeholder
      backgroundColor: PLACEHOLDER_BACKGROUND_COLOR,
      borderColor: PLACEHOLDER_BORDER_COLOR,
      fontColor: PRIMARY_TEXT_COLOR,
    },
    selectPlaceholder: { // Select placeholder
      bgColor: PLACEHOLDER_BACKGROUND_COLOR,
      txtColor: PLACEHOLDER_TEXT_COLOR,
    },
    dropDownList: { // For dropdown list
      bgColor: PRIMARY_BACKGROUND_COLOR,
      txtColor: PRIMARY_TEXT_COLOR,
    }, fontColor: {
      txtColor: TEXT_COLOR,
    },
    notification: { //FOR DASHBOARD MAIN BOX CONTAINER
      backgroundColor: NOTIFICATION_COLOR,
      txtColor: TEXT_COLOR,

    }
  },
  colors: {
    primaryBackgroundColor: PRIMARY_BACKGROUND_COLOR,
    secondaryBackgroundColor: SECONDARY_BACKGROUND_COLOR,
    primaryTextColor: PRIMARY_TEXT_COLOR,
    secondaryTextColor: SECONDARY_TEXT_COLOR,
    buttonBackgroundColor: BUTTON_BACKGROUND_COLOR,
    buttonTextColor: BUTTON_TEXT_COLOR,
    activeTxtColor: PRIMARY_TEXT_COLOR,
    nonActiveTxtColor: ALT_TEXT_COLOR,
    activeBgColor: BUTTON_BACKGROUND_COLOR,
    nonActiveBgColor: PRIMARY_BACKGROUND_COLOR,
    placeholderTextColor: PLACEHOLDER_TEXT_COLOR,
    lightTextColor: TEXT_COLOR,
    darkTextColor: ALT_TEXT_COLOR
  },
});

export default whiteOrangeTheme;
