// 19-7-24 FOR HANDLE THEME GLOBALLY

import { createTheme } from '@mui/material/styles';
//  linear-gradient(135deg, #007991, #78ffd6)
// Define global color variables
const GRADIENT_BACKGROUND_COLOR = 'linear-gradient(135deg, #007991, #78ffd6)';
const DARK_BACKGROUND_COLOR = GRADIENT_BACKGROUND_COLOR;
const LIGHT_BACKGROUND_COLOR = '#e6fdff';
const DARK_TEXT_COLOR = '#FFFFFF';
const LIGHT_TEXT_COLOR = '#FFFFFF';
const BUTTON_TEXT_COLOR = '#007991';
const BUTTON_BACKGROUND_COLOR = ` #007991`;
const PLACEHOLDER_BACKGROUND_COLOR = 'rgb(230, 253, 255)';
const PLACEHOLDER_BORDER_COLOR = '#889898';
const PLACEHOLDER_TEXT_COLOR = '#FFFFFF';
const SECONDARY_TEXT_COLOR = '#ABBBC2';
const TEXT_COLOR = '#FFFFFF';
const NOTIFICATION_COLOR_BG = ''
const NOTIFICATION_COLOR = '#1F1D2B'

const TABLE_HEADER_COLOR_BG = `#007991`
const TABLE_COLOR_BG = `#007991`
const TABLE_TEXT_COLOR = '#FFFFFF'

const LARGE_TITLE_COLOR = ''


// Create a theme with global color variables
const LightTheme = createTheme({
    palette: {
        // primary: {
        //     main: DARK_BACKGROUND_COLOR,
        // },
        background: {
            default: DARK_BACKGROUND_COLOR,
            paper: `#007991`,
        },
        text: { //FOR FORM LABEL COLOR
            primary: LIGHT_TEXT_COLOR,
        },
    },
    typography: {
        fontFamily: 'Georgia',
        fontSize: 14,
    },
    custom: {
        header: {
            backgroundColor: `#00979e`,
            color: LIGHT_TEXT_COLOR,
        },
        sideBar: {
            backgroundColor: DARK_BACKGROUND_COLOR,
            color: LIGHT_TEXT_COLOR,
        },
        main: {
            backgroundColor: DARK_BACKGROUND_COLOR,
        },
        submitButton: {
            backgroundColor: BUTTON_BACKGROUND_COLOR,
            textColor: LIGHT_TEXT_COLOR,
        },
        cancelButton: {
            backgroundColor: LIGHT_BACKGROUND_COLOR,
            textColor: BUTTON_TEXT_COLOR,
            borderColor: DARK_TEXT_COLOR //ORANGE
        },
        placeholder: { //NORMAL TEXT PLACE HOLDER
            backgroundColor: PLACEHOLDER_BACKGROUND_COLOR,
            borderColor: PLACEHOLDER_BORDER_COLOR,
            fontColor: `#007991`
        },
        selectPlaceholder: { //SELECT  PLACEHOLDER
            bgColor: PLACEHOLDER_BACKGROUND_COLOR,
            txtColor: PLACEHOLDER_TEXT_COLOR
        },
        dropDownList: {
            bgColor: DARK_BACKGROUND_COLOR,
            txtColor: LIGHT_TEXT_COLOR
        },
        fontColor: {
            txtColor: TEXT_COLOR,
        },
        BoxStyles: { //FOR DASHBOARD MAIN BOX CONTAINER
            borderRadius: 3,
            margin: '0px',
            padding: '3rem',
        },
        notification: { //FOR DASHBOARD MAIN BOX CONTAINER 
            backgroundColor: NOTIFICATION_COLOR_BG,
            txtColor: NOTIFICATION_COLOR,

        },
        dataTable: { //FOR DASHBOARD MAIN BOX CONTAINER 
            backgroundColor: TABLE_COLOR_BG,
            txtColor: TABLE_TEXT_COLOR,
            headeColor: TABLE_HEADER_COLOR_BG

        }, checkBox: { //FOR DASHBOARD MAIN BOX CONTAINER 
            backgroundColor: DARK_TEXT_COLOR,
            txtColor: DARK_TEXT_COLOR,
            headeColor: DARK_TEXT_COLOR

        }, PopupModal: { //FOR DASHBOARD MAIN BOX CONTAINER 
            backgroundColor: DARK_BACKGROUND_COLOR,
            txtColor: DARK_TEXT_COLOR,
            headeColor: DARK_TEXT_COLOR

        },
    },
    colors: {
        darkBackgroundColor: DARK_BACKGROUND_COLOR,
        lightBackgroundColor: LIGHT_BACKGROUND_COLOR,
        darkTextColor: DARK_TEXT_COLOR,
        lightTextColor: LIGHT_TEXT_COLOR,
        buttonBackgroundColor: BUTTON_BACKGROUND_COLOR,
        buttonTextColor: DARK_TEXT_COLOR,
        activeTxtColor: DARK_TEXT_COLOR,
        nonActiveTxtColor: `#757575`,
        activeBgColor: DARK_BACKGROUND_COLOR,
        nonActiveBgColor: `#E0E0E0`,
        secondaryTextColor: SECONDARY_TEXT_COLOR,
        largeTitleColor: '#007991',
        smallTitleColor: LARGE_TITLE_COLOR,
        CounterBtnLabelColor: BUTTON_TEXT_COLOR,
        CounterBtnBgColor: LIGHT_BACKGROUND_COLOR
    }
});

export default LightTheme;
