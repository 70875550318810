// 19-7-24 FOR HANDLE THEME GLOBALLY
import { createTheme } from '@mui/material/styles';

// Define global color variables for green shades
const DARK_BACKGROUND_COLOR = '#003366'; // Deep Navy Blue
const LIGHT_BACKGROUND_COLOR = '#b3cfeaa1';  // Medium Navy Blue
const DARK_TEXT_COLOR = '#FFFFFF'; // white color
const LIGHT_TEXT_COLOR = '#f0f0f0'; // Light Gray
const BUTTON_TEXT_COLOR = '#ffffff'; // White
const BUTTON_BACKGROUND_COLOR = '#00509E'; // Moderate Blue
const PLACEHOLDER_BACKGROUND_COLOR = '#b3cfeaa1'; // Deep Navy Blue
const PLACEHOLDER_BORDER_COLOR = '#004080'; // Medium Navy Blue
const PLACEHOLDER_TEXT_COLOR = '#B0BEC5'; // Light Grayish Blue
const SECONDARY_TEXT_COLOR = '#80C0FF'; // Light Sky Blue
const TEXT_COLOR = '#FFFFFF';
const NOTIFICATION_COLOR_BG = '#004080'
const NOTIFICATION_COLOR = '#FFFFFF'
const DARK_TEXT_COLOR2 = '#003366' //FOR 

const TABLE_HEADER_COLOR_BG = '#003366'
const TABLE_COLOR_BG = '#003366'
const TABLE_TEXT_COLOR = '#FFFFFF'

const LARGE_TITLE_COLOR = '#FFFFFF'

// Create a theme with global color variables
const darkBlueWhiteTheme = createTheme({
  palette: {
    // primary: {
    //     main: DARK_BACKGROUND_COLOR,
    // },
    background: {
      default: DARK_BACKGROUND_COLOR,
      paper: '#1E1E1E',
    },
    text: { //FOR FORM LABEL COLOR
      primary: LIGHT_TEXT_COLOR,
    },
  },
  typography: {
    fontFamily: 'Georgia',
    fontSize: 14,
  },
  custom: {
    header: {
      backgroundColor: DARK_BACKGROUND_COLOR,
      color: LIGHT_TEXT_COLOR,
    },
    sideBar: {
      backgroundColor: DARK_BACKGROUND_COLOR,
      color: LIGHT_TEXT_COLOR,
    },
    main: {
      backgroundColor: DARK_BACKGROUND_COLOR,
    },
    submitButton: {
      backgroundColor: BUTTON_BACKGROUND_COLOR,
      textColor: BUTTON_TEXT_COLOR,
    },
    cancelButton: {
      backgroundColor: LIGHT_BACKGROUND_COLOR,
      textColor: BUTTON_TEXT_COLOR,
      borderColor: DARK_TEXT_COLOR //
    },
    placeholder: { //NORMAL TEXT PLACE HOLDER
      backgroundColor: PLACEHOLDER_BACKGROUND_COLOR,
      borderColor: PLACEHOLDER_BORDER_COLOR,
      fontColor: LIGHT_TEXT_COLOR
    },
    selectPlaceholder: { //SELECT  PLACEHOLDER
      bgColor: PLACEHOLDER_BACKGROUND_COLOR,
      txtColor: PLACEHOLDER_TEXT_COLOR
    },
    dropDownList: {
      bgColor: DARK_BACKGROUND_COLOR,
      txtColor: LIGHT_TEXT_COLOR
    },
    fontColor: {
      txtColor: TEXT_COLOR,
    },
    BoxStyles: { //FOR DASHBOARD MAIN BOX CONTAINER
      borderRadius: 3,
      margin: '0px',
      padding: '3rem',
    },
    notification: { //FOR DASHBOARD MAIN BOX CONTAINER 
      backgroundColor: NOTIFICATION_COLOR_BG,
      txtColor: NOTIFICATION_COLOR,

    },
    dataTable: { //FOR DASHBOARD MAIN BOX CONTAINER 
      backgroundColor: TABLE_COLOR_BG,
      txtColor: TABLE_TEXT_COLOR,
      headeColor: TABLE_HEADER_COLOR_BG

    }, checkBox: { //FOR DASHBOARD MAIN BOX CONTAINER 
      backgroundColor: DARK_TEXT_COLOR,
      txtColor: DARK_TEXT_COLOR,
      headeColor: DARK_TEXT_COLOR

    }, PopupModal: { //FOR DASHBOARD MAIN BOX CONTAINER 
      backgroundColor: DARK_BACKGROUND_COLOR,
      txtColor: DARK_TEXT_COLOR,
      headeColor: DARK_TEXT_COLOR

    },
  },
  colors: {
    darkBackgroundColor: DARK_BACKGROUND_COLOR,
    lightBackgroundColor: LIGHT_BACKGROUND_COLOR,
    darkTextColor: DARK_TEXT_COLOR,
    lightTextColor: LIGHT_TEXT_COLOR,
    buttonBackgroundColor: BUTTON_BACKGROUND_COLOR,
    buttonTextColor: BUTTON_TEXT_COLOR,
    activeTxtColor: LIGHT_TEXT_COLOR,
    nonActiveTxtColor: DARK_TEXT_COLOR,
    activeBgColor: BUTTON_BACKGROUND_COLOR,
    nonActiveBgColor: DARK_BACKGROUND_COLOR,
    secondaryTextColor: SECONDARY_TEXT_COLOR,
    largeTitleColor: LARGE_TITLE_COLOR,
    smallTitleColor: LARGE_TITLE_COLOR,
    CounterBtnLabelColor: BUTTON_TEXT_COLOR,
    CounterBtnBgColor: LIGHT_BACKGROUND_COLOR
  }
});
// // Create a theme with global color variables and urgency colors
// const darkBlueWhiteTheme = createTheme({
//   palette: {
//     background: {
//       default: PRIMARY_BACKGROUND_COLOR,
//       paper: '#002244', // Darker Navy Blue
//     },
//     text: {
//       primary: PRIMARY_TEXT_COLOR, // Form label color
//     },
//   },
//   typography: {
//     fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//     fontSize: 14,
//   },
//   custom: {
//     header: {
//       backgroundColor: PRIMARY_BACKGROUND_COLOR,
//       color: PRIMARY_TEXT_COLOR,
//     },
//     sideBar: {
//       backgroundColor: PRIMARY_BACKGROUND_COLOR,
//       color: PRIMARY_TEXT_COLOR,
//     },
//     main: {
//       backgroundColor: PRIMARY_BACKGROUND_COLOR,
//     },
//     submitButton: {
//       backgroundColor: BUTTON_BACKGROUND_COLOR,
//       textColor: BUTTON_TEXT_COLOR,
//     },
//     cancelButton: {
//       backgroundColor: SECONDARY_BACKGROUND_COLOR,
//       textColor: BUTTON_TEXT_COLOR,
//       borderColor: PRIMARY_TEXT_COLOR,
//     },
//     placeholder: { // Normal text placeholder
//       backgroundColor: PLACEHOLDER_BACKGROUND_COLOR,
//       borderColor: PLACEHOLDER_BORDER_COLOR,
//       fontColor: PRIMARY_TEXT_COLOR,
//     },
//     selectPlaceholder: { // Select placeholder
//       bgColor: PLACEHOLDER_BACKGROUND_COLOR,
//       txtColor: PLACEHOLDER_TEXT_COLOR,
//     },
//     dropDownList: { // For dropdown list
//       bgColor: PRIMARY_BACKGROUND_COLOR,
//       txtColor: PRIMARY_TEXT_COLOR,
//     }, fontColor: {
//       txtColor: TEXT_COLOR,
//     },
//   },
//   colors: {
//     primaryBackgroundColor: PRIMARY_BACKGROUND_COLOR,
//     secondaryBackgroundColor: SECONDARY_BACKGROUND_COLOR,
//     primaryTextColor: PRIMARY_TEXT_COLOR,
//     secondaryTextColor: SECONDARY_TEXT_COLOR,
//     buttonBackgroundColor: BUTTON_BACKGROUND_COLOR,
//     buttonTextColor: BUTTON_TEXT_COLOR,
//     activeTxtColor: PRIMARY_TEXT_COLOR,
//     nonActiveTxtColor: ALT_TEXT_COLOR,
//     activeBgColor: BUTTON_BACKGROUND_COLOR,
//     nonActiveBgColor: PRIMARY_BACKGROUND_COLOR,
//     placeholderTextColor: PLACEHOLDER_TEXT_COLOR,
//     lightTextColor: TEXT_COLOR,
//     darkTextColor: ALT_TEXT_COLOR
//   },
// });
export default darkBlueWhiteTheme;
