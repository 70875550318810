import React, { createContext, useState } from 'react';
import blackOrangeTheme from '../blackOrangeTheme';
import greenLightGreenTheme from '../greenLightGreenTheme';
import whiteOrangeTheme from '../whiteOrangeTheme';
import darkBlueWhiteTheme from '../darkBlueWhiteTheme';
import LightTheme from '../LightTheme';
const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {

  //FOR MANAGE THEMES GLOBALLY WHEN RELOAD APPLICATION
  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem('appTheme');
    const parsedTheme = savedTheme ? JSON.parse(savedTheme) : blackOrangeTheme;
    switch (parsedTheme) {
      case "greenLightGreenTheme":
        return { value: greenLightGreenTheme, id: parsedTheme };
      case "darkBlueWhiteTheme":
        return { value: darkBlueWhiteTheme, id: parsedTheme };
      case "whiteOrangeTheme":
        return { value: whiteOrangeTheme, id: parsedTheme };
        case "LightTheme":
          return { value: LightTheme, id: parsedTheme };
      default:
        return { value: blackOrangeTheme, id: 'blackOrangeTheme' };
    }
  };
  const res = getInitialTheme()
  const [theme, setTheme] = useState(res?.value);
  const [prevTheme, setPrevTheme] = useState(res?.id);

  const handleThemeChange = (newTheme, themeName) => {
    setTheme(newTheme);
    localStorage.setItem('appTheme', JSON.stringify(themeName));
  };

  return (
    <ThemeContext.Provider value={{ theme, handleThemeChange, prevTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
