// 19-7-24 FOR HANDLE THEME GLOBALLY greenLightGreenTheme
import { createTheme } from '@mui/material/styles';


// Define global color variables for green shades
const DARK_BACKGROUND_COLOR = '#003300'; // Dark Green
const LIGHT_BACKGROUND_COLOR = '#99cc99'; // Soft Light Green
const DARK_TEXT_COLOR = '#004d00'; // Darker Green
const LIGHT_TEXT_COLOR = '#f0f0f0'; // Light Gray
const BUTTON_TEXT_COLOR = '#ffffff'; // White
const BUTTON_BACKGROUND_COLOR = '#4caf50'; // Medium Green
const PLACEHOLDER_BACKGROUND_COLOR = '#4caf50'; // Darker Green #66ff66
const PLACEHOLDER_BORDER_COLOR = '#66ff66'; // Light Green
const PLACEHOLDER_TEXT_COLOR = '#f0f0f0'; // Light Gray
const SECONDARY_TEXT_COLOR = '#a4c639'; // Muted Light Green
const TEXT_COLOR = '#FFFFFF';
const NOTIFICATION_COLOR_BG = 'rgb(153, 204, 153)'
const NOTIFICATION_COLOR = '#003300'

const TABLE_HEADER_COLOR_BG = '#003300'
const TABLE_COLOR_BG = '#003300'
const TABLE_TEXT_COLOR = '#FFFFFF'

const LARGE_TITLE_COLOR = '#003300'


// Create a theme with global color variables and urgency colors
const greenLightGreenTheme = createTheme({
  palette: {
    // primary: {
    //     main: DARK_BACKGROUND_COLOR,
    // },
    background: {
      default: DARK_BACKGROUND_COLOR,
      paper: DARK_BACKGROUND_COLOR,
    },
    text: { //FOR FORM LABEL/TITLE COLOR
      primary: LIGHT_TEXT_COLOR,
    },
  },
  typography: {
    fontFamily: 'Georgia',
    fontSize: 14,
  },
  custom: {
    header: {
      backgroundColor: DARK_BACKGROUND_COLOR,
      color: LIGHT_TEXT_COLOR,
    },
    sideBar: {
      backgroundColor: DARK_BACKGROUND_COLOR,
      color: LIGHT_TEXT_COLOR,
    },
    main: {
      backgroundColor: DARK_BACKGROUND_COLOR,
    },
    submitButton: {
      backgroundColor: BUTTON_BACKGROUND_COLOR,
      textColor: BUTTON_TEXT_COLOR,
    },
    cancelButton: {
      backgroundColor: LIGHT_BACKGROUND_COLOR,
      textColor: BUTTON_TEXT_COLOR,
      borderColor: DARK_TEXT_COLOR //ORANGE
    },
    placeholder: { //NORMAL TEXT PLACE HOLDER
      backgroundColor: PLACEHOLDER_BACKGROUND_COLOR,
      borderColor: PLACEHOLDER_BORDER_COLOR,
      fontColor: LIGHT_TEXT_COLOR
    },
    selectPlaceholder: { //SELECT  PLACEHOLDER
      bgColor: PLACEHOLDER_BACKGROUND_COLOR,
      txtColor: PLACEHOLDER_TEXT_COLOR
    },
    dropDownList: {
      bgColor: DARK_BACKGROUND_COLOR,
      txtColor: LIGHT_TEXT_COLOR
    },
    fontColor: {
      txtColor: TEXT_COLOR,
    },
    BoxStyles: { //FOR DASHBOARD MAIN BOX CONTAINER
      borderRadius: 3,
      margin: '0px',
      padding: '3rem',
    },
    notification: { //FOR DASHBOARD MAIN BOX CONTAINER 
      backgroundColor: NOTIFICATION_COLOR_BG,
      txtColor: NOTIFICATION_COLOR,

    },
    dataTable: { //FOR DASHBOARD MAIN BOX CONTAINER 
      backgroundColor: TABLE_COLOR_BG,
      txtColor: TABLE_TEXT_COLOR,
      headeColor: TABLE_HEADER_COLOR_BG

    }, checkBox: { //FOR DASHBOARD MAIN BOX CONTAINER 
      backgroundColor: LIGHT_BACKGROUND_COLOR,
      txtColor: LIGHT_BACKGROUND_COLOR,
      headeColor: LIGHT_BACKGROUND_COLOR

    }, PopupModal: { //FOR DASHBOARD MAIN BOX CONTAINER 
      backgroundColor: DARK_BACKGROUND_COLOR,
      txtColor: DARK_TEXT_COLOR,
      headeColor: DARK_TEXT_COLOR

    },
  },
  colors: {
    darkBackgroundColor: DARK_BACKGROUND_COLOR,
    lightBackgroundColor: LIGHT_BACKGROUND_COLOR,
    darkTextColor: DARK_TEXT_COLOR,
    lightTextColor: LIGHT_TEXT_COLOR,
    buttonBackgroundColor: BUTTON_BACKGROUND_COLOR,
    buttonTextColor: BUTTON_TEXT_COLOR,
    activeTxtColor: LIGHT_TEXT_COLOR,
    nonActiveTxtColor: LIGHT_TEXT_COLOR,
    activeBgColor: BUTTON_BACKGROUND_COLOR,
    nonActiveBgColor: DARK_BACKGROUND_COLOR,
    secondaryTextColor: SECONDARY_TEXT_COLOR,
    largeTitleColor: LARGE_TITLE_COLOR,
    smallTitleColor: TABLE_TEXT_COLOR,
    CounterBtnLabelColor: BUTTON_TEXT_COLOR,
    CounterBtnBgColor: LIGHT_BACKGROUND_COLOR
  }
});
export default greenLightGreenTheme;
